<template>
  <div class="home-page">
    <div class="slider">
      <vue-tiny-slider
        ref="vmslider"
        :mouse-drag="isMobileDevice || isTabletDevice"
        :nav="false"
        :controls="false"
        :loop="true"
        items="1"
        mode="gallery"
      >
        <div
          class="slider__item"
          v-for="index in 6"
          :key="`slider_${index}`"
        >
          <div class="slider-bg" :style="`background-image: url(${require('@/assets/home/slider' + index +'.jpg')})`"></div>
        </div>
      </vue-tiny-slider>
      <div class="slider__controls">
        <div class="container">
          <button type="button" class="control-custom control-left" @click="onPrevStep">
            <img src="@/assets/icon-left-arrow.png" />
          </button>
          <button type="button" class="control-custom control-right" @click="onNextStep">
            <img src="@/assets/icon-right-arrow.png" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueTinySlider from 'vue-tiny-slider'

export default {
  name: 'HomePage',
  components: {
    VueTinySlider
  },
  data: () => ({
    sliderIndex: 1,
    heightPage: 1
  }),
  mounted() {
    this.$nextTick(() => {
      const wHeight = window.innerHeight
      this.heightPage = wHeight - this.heightHeaderApp
    })
  },
  methods: {
    onNextStep() {
      console.log('next')
      this.$refs.vmslider.slider.goTo('next')
      const { displayIndex } = this.$refs.vmslider.slider.getInfo()
      this.sliderIndex = displayIndex
    },
    onPrevStep() {
      console.log('prev')
      this.$refs.vmslider.slider.goTo('prev')
      const { displayIndex } = this.$refs.vmslider.slider.getInfo()
      this.sliderIndex = displayIndex
    },
    goToSlider(step) {
      console.log(step)
      this.sliderIndex = step
      this.$refs.vmslider.slider.goTo(step - 1)
    },
    showDialogSign(type) {
      this.$eventBus.$emit('show-sign', type);
    }
  }
}
</script>